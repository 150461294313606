<template>
	<div ref="content">
		<div class="child-top" style="height: 50px">
			<div class="searchArea input-from">
				<!--运动队设置-->
				<el-input class="input-with-select" clearable placeholder="请输入关键字" style="width: 200px" suffix-icon="iconfont iconsousuo" v-model="keyword" @keyup.enter.native="getData">
				</el-input>
				<el-button class="searchBtn" @click="getData()" round>搜索</el-button>
			</div>
			<div class="operation-button">
				<!-- <el-button @click="capture" class="new_btn" round>
					<span>截屏</span>
				</el-button> -->
				<el-button @click="addButton()" class="new_btn" :disabled="$store.getters.permissionsStr('新增')" round>
					<span>新增</span>
				</el-button>
			</div>
		</div>

		<!-- 表格 -->
		<div class="table">
			<el-table
				:empty-text="tableData.length ? '' : '暂无数据'"
				:data="!$store.getters.permissionsStr('列表') ? tableData : []"
				style="width: 100%; margin-bottom: 20px"
				row-key="uuid"
				@selection-change="selsChange"
				:tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
			>
				<el-table-column type="selection" width="55" sortable></el-table-column>
				<el-table-column label="序号" width="60" type="index" align="center"></el-table-column>

				<el-table-column prop="name" label="运动项目名称" align="left" miax-width="240"> </el-table-column>
				<el-table-column label="状态" prop="status" width="80" show-overflow-tooltip>
					<template slot-scope="scope">
						<el-switch
							v-show="scope.row.uuid"
							v-model="scope.row.status"
							active-color="#BDC6CF"
							inactive-color="#13ce66"
							:active-value="0"
							:inactive-value="1"
							style="width: 35px"
							@change="changeStatus2(scope.row)"
						></el-switch>
					</template>
				</el-table-column>
				<el-table-column label="操作" max-width="270" align="center">
					<template slot-scope="scope">
						<el-button style="color: #2493e7" size="medium" type="text" @click="editButton2(scope.row)" :disabled="$store.getters.permissionsStr('编辑')">编辑</el-button>
						<el-button style="color: #f05756" size="medium" type="text" @click="delTableData(scope.row)" :disabled="$store.getters.permissionsStr('删除')">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<!-- 弹框 -->
		<el-dialog @close="resetForm()" :close-on-click-modal="false" :close-on-press-escape="false" :title="title2" :destroy-on-close="true" :visible.sync="dialogFormVisible2" width="30%">
			<el-form :rules="rules" :model="form2" ref="ruleForm1">
				<el-form-item label="级别" prop="puuid" label-width="100px">
					<el-cascader
						v-model="form2.puuid"
						:options="newData"
						style="width: 100%"
						:placeholder="form2.zi == '' ? '请选择' : form2.zi"
						clearable
						filterable
						:props="{
							checkStrictly: true,
							value: 'uuid',
							label: 'name',
							emitPath: false,
						}"
					>
					</el-cascader>
				</el-form-item>
				<el-form-item label="项目名称" label-width="100px" prop="name">
					<el-input @input="form2.name = form2.name" v-model="form2.name" clearable></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button
					@click="
						resetForm()
						dialogFormVisible2 = false
					"
					>取 消</el-button
				>
				<el-button type="primary" @click="addForm2()">保存</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import html2canvas from 'html2canvas'

export default {
	name: 'WorkspaceJsonProjectsettings',

	data() {
		return {
			tableData: [],
			form2: {
				uuid: '',
				name: '',
				puuid: [],
				name: '',
				zi: '',
			},
			keyword: '',
			title2: '', //弹框名称
			dialogFormVisible2: false, //默认弹框
			newData: [], //隶属分类数据
			handleType2: 1,
			sels: [], // 列表选中列
			// 判断验证
			rules: {
				name: [
					{
						required: true,
						message: '请输入项目名称',
						trigger: 'blur',
					},
					{
						min: 1,
						max: 20,
						message: '限制在20个汉字以内',
						trigger: 'blur',
					},
				],
				puuid: {
					required: true,
					message: '请选择级别',
					trigger: 'blur',
				},
			},
		}
	},

	mounted() {
		this.$nextTick(() => {
			this.useTimeList()
			this.getData()
		})
	},

	methods: {
		capture() {},
		// 获取列表
		getData() {
			let params = {
				// page: this.page,
				// page_size: this.limit,
				keyword: this.keyword,
			}
			let url = '/p/sportproject/lists'
			this.$axios.post(url, this.$qs(params)).then(res => {
				if (res.data.code === 0) {
					this.tableData = res.data.data
				}
			})
		},
		// 获取级别列表
		useTimeList() {
			let params = {}
			if (this.handleType2 === 2) {
				params = {
					flag: 1,
					uuid: this.form2.uuid,
				}
			}
			this.$axios.post('p/sportproject/getSportData', this.$qs(params)).then(res => {
				this.newData = res.data.data
				/*  让无限极联动器 到第3级  */
				// let arr = JSON.parse(JSON.stringify(res.data.data));
				// let aa=[]
				// arr.forEach((item, idx) => {
				// if (item.children && item.children.length) {
				//     item.children.forEach((itm, ix) => {
				//     if (itm.children && itm.children.length) {
				//         delete itm.children;
				//     }
				//     });
				// }
				// aa.push(item);
				//     this.newData = [...new Set(aa)]
				// });
				this.newData.unshift({
					name: '顶级分类',
					uuid: '0',
					key: '0',
					puuid: '0',
					level: 1,
				})
			})
		},
		// 新增
		addButton() {
			this.useTimeList()
			this.handleType2 = 1
			this.title2 = '新建运动项目'
			this.dialogFormVisible2 = true
			// this.form2.zi = "顶级分类";
			return false
		},
		// 弹框取消
		resetForm() {
			this.form2.name = ''
			this.form2.name = ''
			this.form2.uuid = ''
			this.form2.puuid = []
			this.form2.zi = ''
		},
		// 弹框确认
		addForm2() {
			if (this.handleType2 === 1) {
				let url = '/p/sportproject/add'
				let params = {
					name: this.form2.name,
					puuid: this.form2.puuid,
				}
				this.$refs.ruleForm1.validate(valid => {
					if (valid) {
						this.$axios.post(url, this.$qs(params)).then(res => {
							if (res.data.code === 0) {
								this.dialogFormVisible2 = false

								this.$message({
									message: res.data.message,
									type: 'success',
								})
								this.getData()
							} else {
								this.$message({
									message: res.data.message,
									type: 'error',
								})
							}
						})
					}
				})
			} else {
				let url = '/p/sportproject/update'
				let params = {
					name: this.form2.name,
					puuid: this.form2.puuid,
					uuid: this.form2.uuid,
				}
				this.$refs.ruleForm1.validate(valid => {
					if (valid) {
						this.$axios.post(url, this.$qs(params)).then(res => {
							if (res.data.code === 0) {
								this.dialogFormVisible2 = false

								this.$message({
									message: res.data.message,
									type: 'success',
								})
								this.getData()
							} else {
								this.$message({
									message: res.data.message,
									type: 'error',
								})
							}
						})
					}
				})
			}
		},
		// 编辑
		editButton2(data) {
			this.handleType2 = 2
			this.form2.name = data.name
			this.form2.zi = '顶级分类'
			this.form2.uuid = data.uuid
			this.form2.puuid = data.puuid
			this.title2 = '编辑运动项目'
			this.dialogFormVisible2 = true
			this.useTimeList()
		},
		// //编辑表单中表格的复选框选中与取消
		selsChange(sels) {
			this.sels = sels
		},
		// 单行删除
		delTableData(row) {
			this.$confirm('确定删除数据吗?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
				center: true,
			}).then(() => {
				this.$axios
					.post(
						'/p/sportproject/del',
						this.$qs({
							uuids: row.uuid,
						})
					)
					.then(res => {
						if (res.data.code === 0) {
							this.$message({
								message: res.data.message,
								type: 'success',
							})
							this.getData()
						} else {
							this.$utils.message({
								type: 'error',
								message: res.data.message,
							})
						}
					})
					.catch(error => {})
			})
		},
		// 多行删除
		someDels() {
			let that = this
			//删除数据
			if (that.sels.length == 0) {
				that.$message({
					message: '请选择要删除的数据',
					type: 'error',
					duration: 4000,
				})
				return
			}
			this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'success',
				closeOnClickModal: false,
				cancelButtonClass: '',
				center: true,
			}).then(() => {
				let params = []
				let url = '/p/sportproject/del'
				for (let i = 0; i < this.sels.length; i++) {
					params[i] = this.sels[i].uuid
				}
				let ids = params.join(',')
				let data = { uuids: ids }
				this.$axios.post(url, this.$qs(data)).then(res => {
					if (res.data.code == 0) {
						this.getData()
						this.$message({
							message: res.data.message,
							type: 'success',
						})
					} else {
						this.$message({
							message: res.data.message,
							type: 'error',
						})
					}
				})
			})
		},
		// 状态
		changeStatus2(data) {
			let params = {
				uuid: data.uuid,
			}
			//获取列表
			let url = '/p/sportproject/able'
			this.$axios
				.post(url, this.$qs(params))
				.then(response => {
					if (response.data.code == 0) {
						this.$message({
							message: '切换成功',
							type: 'success',
						})
						this.getData(this.currentPage)
					}
				})
				.catch(error => {
					// console.log(error);
				})
		},
	},
}
</script>

<style lang="scss" scoped>
/deep/ .el-dialog__header {
	text-align: center;
}
/deep/ .el-form-item__label {
	text-align: left;
	padding-left: 40px;
}
</style>
